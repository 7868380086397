<template>
    <div class="cent-sub-menu">
        <button 
                v-if="selectedLanguage=='en'"
                @click="prevClick"
                :class="{ 'disabled': isPrevButtonDisabled }"
                :disabled="isPrevButtonDisabled"
                class="cent-sub-menu-prev"
                type="button"
                >
            <span class="cent-sub-menu-prev-icon"
                  aria-hidden="true"></span>
        </button>
        <button 
                v-if="selectedLanguage=='ar'"
                @click="prevClick"
                :class="{ 'disabled': isPrevButtonDisabled }"
                :disabled="isPrevButtonDisabled"
                class="cent-sub-menu-next"
                type="button"
                >
            <span class="cent-sub-menu-next-icon"
                  aria-hidden="true"></span>
        </button>

        <nav aria-labelledby="block-centurionmenu-2-menu"
             id="block-centurionmenu-2"
             class="block block-menu navigation menu--centurion-menu">
            <h5 class="visually-hidden"
                id="block-centurionmenu-2-menu">{{ $t('Centurion Menu') }}</h5>
            <ul class="menu"
                :style="menuStyle">
                <li class="menu-item menu-item--active-trail">
                    <router-link class="nav-link"
                                 to="/centurion-automotive"
                                 active-class="is-active">
                        {{ $t('Automotive') }}
                    </router-link>
                </li>
                <li class="menu-item">
                    <router-link class="nav-link"
                                 to="/centurion-experience"
                                 active-class="is-active">
                        {{ $t('Centurion Experience') }}
                    </router-link>
                </li>
                <li class="menu-item">
                    <router-link class="nav-link"
                                 to="/centurion-entertainment"
                                 active-class="is-active">
                        {{ $t('Entertainment') }}
                    </router-link>
                </li>
                <li class="menu-item">
                    <router-link class="nav-link"
                                 to="/centurion-jewellery"
                                 active-class="is-active">
                        {{ $t('Jewellery') }}
                    </router-link>
                </li>
                <li class="menu-item">
                    <router-link class="nav-link"
                                 to="/centurion-shopping"
                                 active-class="is-active">
                        {{ $t('Shopping Experiences') }}
                    </router-link>
                </li>
                <li class="menu-item">
                    <router-link class="nav-link"
                                 to="/centurion-travel"
                                 active-class="is-active">
                        {{ $t('Travel Offers') }}
                    </router-link>
                </li>
            </ul>
        </nav>

        <button 
                v-if="selectedLanguage=='en'"
                @click="nextClick"
                :class="{ 'disabled': isNextButtonDisabled }"
                :disabled="isNextButtonDisabled"
                class="cent-sub-menu-next"
                type="button">
            <span class="cent-sub-menu-next-icon"
                  aria-hidden="true"></span>
        </button>
        <button 
                v-if="selectedLanguage=='ar'"
                @click="nextClick"
                :class="{ 'disabled': isNextButtonDisabled }"
                :disabled="isNextButtonDisabled"
                class="cent-sub-menu-prev"
                type="button">
            <span class="cent-sub-menu-prev-icon"
                  aria-hidden="true"></span>
        </button>
    </div>
</template>

<script>
import store from '@/store';

export default {
    data: () => ({
        selectedLanguage: 'en',
        translateNB: 0, // Translate index
        translateVal: 0, // Translate value in pixels
        itemWidth: 0, // Item width (dynamically calculated)
        visibleItems: 3, // Number of visible items at a time
        // transform: null,
    }),
    created()
    {
        this.unsubscribe = store.subscribe((mutation) =>
        {
            // if (mutation.type === 'accounts/SET_LANGUAGE')
            // {
            //     this.selectedLanguage = mutation.payload;
            //     if (store.get('requestKeys/accessToken') && this.loadedCount == 0)
            //     {
            //         this.loadedCount++;
            //     }

            // }
            if (mutation.type === 'accounts/SET_LANGUAGE') {
            const oldLanguage = this.selectedLanguage;
            this.selectedLanguage = mutation.payload;

            // Check if the language has changed
            if (oldLanguage !== this.selectedLanguage) {
                this.onLanguageChange(this.selectedLanguage); // Call a custom method if needed
            }

            if (store.get('requestKeys/accessToken') && this.loadedCount == 0) {
                this.loadedCount++;
            }
        }
        })

    },

    destroyed()
    {
        this.unsubscribe();
    },
    mounted()
    {
        let lang = localStorage.getItem('language');
        if (lang)
        {
            this.selectedLanguage = localStorage.getItem('language');
        }
        this.updateTranslate();
        this.initializeMenuPosition();
        window.addEventListener('resize', this.updateTranslate); // Ensure responsiveness
    },
    computed: {
        menuStyle()
        {
            return {
                transform: `translateX(${this.selectedLanguage === 'en' ? this.translateVal : this.translateVal}px)`
            };
        },
        isPrevButtonDisabled()
        {
                return this.translateVal == 0; 
        },
        isNextButtonDisabled()
        {
            var transVal=this.translateVal;
            if(this.selectedLanguage=='en' && this.translateVal<0){
                transVal= this.translateVal*-1
            }
            return transVal >= this.maxTranslateVal;
        },
    },
    methods: {
        onLanguageChange(newLanguage) {
            this.translateVal= 0,
            this.translateNB= 0
        },
        updateTranslate()
        {
            const menuItems = this.$el.querySelectorAll('.menu-item');
            this.itemWidth = menuItems[0] ? menuItems[0].offsetWidth : 0;
            const containerWidth = this.$el.querySelector('.menu').offsetWidth;
            const totalItems = menuItems.length;
            this.maxTranslateVal = Math.max(0, (totalItems * this.itemWidth) - containerWidth);
        },

        initializeMenuPosition()
        {
            const menuItems = this.$el.querySelectorAll('.menu-item');
            const activeItem = this.$el.querySelector('.menu-item.is-active');
            if (activeItem)
            {
                const activeIndex = Array.from(menuItems).indexOf(activeItem);
                const activePosition = activeIndex * this.itemWidth;
                this.translateVal = activePosition - this.itemWidth;
                this.translateVal = Math.max(0, Math.min(this.translateVal, this.maxTranslateVal));
            }
        },

        nextClick()
        {
            console.log('dsds')
            if (this.selectedLanguage === 'en')
            {
                if (this.translateVal < this.maxTranslateVal)
                {
                    this.translateNB--;
                    this.translateVal = this.translateNB * this.itemWidth;
                    // this.translateVal = Math.max(0, this.translateVal);
                }
            } else
            {
                if (this.translateVal < this.maxTranslateVal)
                {
                    this.translateNB++;
                    this.translateVal = this.translateNB * this.itemWidth;
                    this.translateVal = Math.min(this.translateVal, this.maxTranslateVal);
                }
            }

            // this.transform = `translate(${this.selectedLanguage === 'en' ? this.translateVal : -this.translateVal}px)`
        },

        prevClick()
        {
            if (this.selectedLanguage === 'en')
            {
                if (this.translateVal < 0)
                {
                    this.translateNB++;
                    this.translateVal = this.translateNB * this.itemWidth;
                    this.translateVal = Math.min(this.translateVal, this.maxTranslateVal);
                }
            } else
            {
                if (this.translateVal > 0)
                {
                    this.translateNB--;
                    this.translateVal = this.translateNB * this.itemWidth;
                    this.translateVal = Math.max(0, this.translateVal);
                }
            }
            // this.transform = `translate(${this.selectedLanguage === 'en' ? this.translateVal : -this.translateVal}px)`
        },
    },
    destroyed()
    {
        window.removeEventListener('resize', this.updateTranslate); // Clean up on destroy
    },
    watch: {
        items: 'updateTranslate', // Recalculate when items change
    },
};
</script>

<style lang="scss" scoped>
@import './centurion.scss';
@import './bootstrap.css';

.cent-sub-menu {
    width: 100%;
    overflow: hidden;
}

.cent-sub-menu .menu {
    display: flex;
    transition: transform 0.3s ease;
    /* Smooth transition for sliding */
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.gray-white {
    background: linear-gradient(to bottom, #fff 80%, #f8f8f8 20%);
}

.black-bg {
    background-color: #000000;
}

.title {
    font-size: 1.5em;
    font-weight: bold;
    color: #ffffff;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    transform: translateY(1.5em);
    text-align: center;
}

.image img {}

@media only screen and (max-width: 576px) {
    .black-bg {
        margin-bottom: 20%;
    }

    .title {
        font-size: 2em;
        text-align: center;
        margin-top: 1em;
    }

    .image {
        transform: translateY(50%);
        text-align: center;
        margin-top: -25%;
    }
}
</style>
